import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";


const Summer2022Page = () => {
	return (
	  <Layout>
	    <Seo title="Summer 2022" />
		<h2>Canada Summer 2022</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/photos/pressRes/Jesse-high-5.jpg"
			alt="Jesse Rivest press image"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/veuTCPPqZpc"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>

		<h3>House Concerts? August 2022?</h3>
		<p>
			I know, those are question marks.
			That's because I still need to form audiences and venues to make these shows happen.
			I'd really like to make these shows happen!
			The platform I've chosen to facilitate this is Side Door Access, and I'll be available the following dates.
		</p>
		<ul>
			<li>
				<span className="underline">August 8 – 12</span>: Montreal
				<ul><li>{"<"}still looking{">"}</li></ul>
			</li>
			<li>
				August 16 – 25: Kelowna/Okanagan
				<ul>
					<li>
						<span className="underline">Aug 21, 13:00 – 15:00</span> @ Kettle River Brewing<br />
						Open to the public—come have a beer or a snack and hear some music!<br />
						Come say hi 👋<br />
						<a href="//www.facebook.com/events/706097117348969/">Facebook event</a>
					</li>
				</ul>
			</li>
			<li>
				<span className="underline">August 27 – Sept 2</span>: Hamilton (Toronto)
				<ul><li>{"<"}still looking{">"}</li></ul>
			</li>
		</ul>
		<p>
			If you're interested in attending one of the proposed shows listed above,
			please join my <Link to="/support/#mailinglist">mailing list</Link> to be kept in the loop.<br />
			You can always unsubscribe later, but for now this is the best way.<br />
			If you have the time, interest, or means to help make one of the shows happen,
			then please e-mail me, leave me a voice message,
			or <a href="//sidedooraccess.com/artists/JesseRivest">connect with me on Side Door</a>.<br />
			See my <Link to="/contact/">contact page</Link> for e-mail or phone.<br />
			Let's make it happen!
		</p>
		<h3>Get to know my tunes</h3>
		<p>
			That's easy—even if you don't use Spotify you'll see the songs I've chosen for you to get to know:<br />
			<a href="//open.spotify.com/playlist/0GCwFR0jYsbX6QYq9ZsXAa?si=84fe951afe504d63">A brief intro
			to: Jesse Rivest</a>
		</p>
		<h3>Some past house concerts</h3>

		<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/rVbxSSLGWSg"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>

		<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/j8lOvtC7rUU"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>

		<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/7qhTDrcZZNE"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>

	  </Layout>
  );
};

export default Summer2022Page;
